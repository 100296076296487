import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase-config';
import StripePayment from '../components/StripePayment';

const Payment = () => {
  const [paymentAmount, setPaymentAmount] = useState(50);
  const [originalAmount, setOriginalAmount] = useState(50);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [appliedDiscount, setAppliedDiscount] = useState(null);
  const [discountError, setDiscountError] = useState('');
  const [isCheckingDiscount, setIsCheckingDiscount] = useState(false);
  const [stripeInitialized, setStripeInitialized] = useState(false);

  const handleAmountChange = (e) => {
    const newAmount = parseFloat(e.target.value);
    setPaymentAmount(newAmount);
    setOriginalAmount(newAmount);
    // Reset any applied discount when amount changes
    setAppliedDiscount(null);
    setDiscountCode('');
    setDiscountError('');
    setStripeInitialized(false);
  };

  const handleDiscountCodeChange = (e) => {
    setDiscountCode(e.target.value.toUpperCase());
    setDiscountError('');
    setStripeInitialized(false);
  };

  const applyDiscountCode = async () => {
    if (!discountCode.trim()) {
      setDiscountError('Please enter a discount code');
      return;
    }

    setIsCheckingDiscount(true);
    setDiscountError('');
    setStripeInitialized(false);

    try {
      // Query Firestore for the discount code
      const discountQuery = query(
        collection(db, 'discountCodes'),
        where('code', '==', discountCode.trim()),
        where('active', '==', true)
      );
      
      const querySnapshot = await getDocs(discountQuery);
      
      if (querySnapshot.empty) {
        setDiscountError('Invalid or expired discount code');
        setIsCheckingDiscount(false);
        return;
      }
      
      // Get the first matching discount code
      const discountData = querySnapshot.docs[0].data();
      
      // Check if the discount code has expired
      if (discountData.expiryDate && new Date() > discountData.expiryDate.toDate()) {
        setDiscountError('This discount code has expired');
        setIsCheckingDiscount(false);
        return;
      }
      
      // Apply the discount
      const discountPercentage = discountData.percentOff;
      const discountAmount = (originalAmount * discountPercentage) / 100;
      const discountedPrice = Math.max(originalAmount - discountAmount, 0);
      
      setPaymentAmount(discountedPrice);
      setAppliedDiscount({
        code: discountCode,
        percentOff: discountPercentage,
        originalAmount,
        discountedAmount: discountedPrice
      });
      
    } catch (error) {
      console.error('Error applying discount code:', error);
      setDiscountError('Error applying discount code. Please try again.');
    } finally {
      setIsCheckingDiscount(false);
    }
  };

  const removeDiscount = () => {
    setPaymentAmount(originalAmount);
    setAppliedDiscount(null);
    setDiscountCode('');
    setStripeInitialized(false);
  };

  const handleProceedToPayment = () => {
    setShowPaymentForm(true);
    setStripeInitialized(false);
  };

  return (
    <div className="payment-page">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 text-center">Payment</h1>
        
        {!showPaymentForm ? (
          <div className="payment-selection">
            <div className="bg-white p-6 rounded-lg shadow-md max-w-md mx-auto">
              <h2 className="text-xl font-semibold mb-4">Select Payment Amount</h2>
              
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Amount (£)</label>
                <input
                  type="number"
                  min="1"
                  step="0.01"
                  value={paymentAmount}
                  onChange={handleAmountChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              
              <div className="payment-options mb-6">
                <h3 className="text-lg font-medium mb-3">Quick Select</h3>
                <div className="grid grid-cols-3 gap-2">
                  {[25, 50, 100].map((amount) => (
                    <button
                      key={amount}
                      onClick={() => {
                        setPaymentAmount(amount);
                        setOriginalAmount(amount);
                        setAppliedDiscount(null);
                        setDiscountCode('');
                        setStripeInitialized(false);
                      }}
                      className={`py-2 px-4 rounded-md transition-colors ${
                        originalAmount === amount
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
                      }`}
                    >
                      £{amount}
                    </button>
                  ))}
                </div>
              </div>
              
              <div className="discount-code-section mb-6">
                <h3 className="text-lg font-medium mb-3">Discount Code</h3>
                
                {appliedDiscount ? (
                  <div className="applied-discount bg-green-50 border border-green-200 rounded-md p-3 mb-3">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-green-800 font-medium">{appliedDiscount.code} ({appliedDiscount.percentOff}% off)</p>
                        <p className="text-sm text-gray-600">
                          Original: £{appliedDiscount.originalAmount.toFixed(2)} → 
                          Discounted: £{appliedDiscount.discountedAmount.toFixed(2)}
                        </p>
                      </div>
                      <button
                        onClick={removeDiscount}
                        className="text-red-600 hover:text-red-800 text-sm"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="discount-input-group flex">
                    <input
                      type="text"
                      value={discountCode}
                      onChange={handleDiscountCodeChange}
                      placeholder="Enter discount code"
                      className="flex-grow px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                      onClick={applyDiscountCode}
                      disabled={isCheckingDiscount || !discountCode.trim()}
                      className="bg-gray-200 hover:bg-gray-300 text-gray-800 px-4 py-2 rounded-r-md transition-colors disabled:opacity-50"
                    >
                      {isCheckingDiscount ? 'Checking...' : 'Apply'}
                    </button>
                  </div>
                )}
                
                {discountError && (
                  <p className="text-red-600 text-sm mt-1">{discountError}</p>
                )}
              </div>
              
              <button
                onClick={handleProceedToPayment}
                className="w-full bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition-colors font-medium"
              >
                {paymentAmount === 0 
                  ? 'Complete Booking (Free)' 
                  : `Proceed to Payment (£${paymentAmount.toFixed(2)})`}
              </button>
            </div>
          </div>
        ) : (
          <div className="payment-form-container">
            <button
              onClick={() => setShowPaymentForm(false)}
              className="mb-4 flex items-center text-blue-600 hover:text-blue-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back to Amount Selection
            </button>
            
            {paymentAmount > 0 ? (
              <>
                {!stripeInitialized ? (
                  <div className="mb-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
                    <h3 className="text-lg font-semibold mb-2">Payment Summary</h3>
                    <p className="mb-1"><strong>Amount:</strong> £{originalAmount.toFixed(2)}</p>
                    {appliedDiscount && (
                      <p className="mb-1 text-green-600">
                        <strong>Discount:</strong> {appliedDiscount.percentOff}% off ({appliedDiscount.code})
                      </p>
                    )}
                    <p className="text-lg font-bold mt-2">
                      Total: £{paymentAmount.toFixed(2)}
                    </p>
                    <button
                      onClick={() => {
                        // Initialize Stripe payment with the current amount
                        setStripeInitialized(true);
                      }}
                      className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors font-medium"
                    >
                      Confirm and Proceed to Payment
                    </button>
                  </div>
                ) : (
                  <StripePayment
                    amount={paymentAmount}
                    currency="gbp"
                    description="Personal Training Session"
                    discountApplied={appliedDiscount !== null}
                  />
                )}
              </>
            ) : (
              <div className="free-booking-container bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-4 text-center">Complete Your Free Booking</h2>
                <p className="text-center mb-6">
                  Your discount code has reduced the total to £0. You can complete your booking without payment.
                </p>
                <button
                  onClick={async () => {
                    try {
                      // Get the current date and time for the booking
                      const now = new Date();
                      const date = now.toISOString().split('T')[0]; // YYYY-MM-DD
                      const time = `${now.getHours()}:${now.getMinutes()}`; // HH:MM
                      
                      // Call the Firebase function to create a free booking
                      const response = await fetch('https://us-central1-alex-albert.cloudfunctions.net/createFreeBookingHttp', {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          'Origin': window.location.origin,
                        },
                        body: JSON.stringify({
                          data: {
                            name: 'Customer', // In a real app, you would collect this information
                            email: 'customer@example.com', // In a real app, you would collect this information
                            phone: '', // Optional
                            date,
                            time,
                            discountCode: appliedDiscount.code,
                            discountPercentage: appliedDiscount.percentOff,
                            originalAmount: appliedDiscount.originalAmount
                          }
                        })
                      });
                      
                      if (!response.ok) {
                        const errorData = await response.json();
                        throw new Error(errorData.error || 'Failed to create booking');
                      }
                      
                      const result = await response.json();
                      
                      if (result.result && result.result.success) {
                        alert('Your free booking has been confirmed!');
                        // Navigate to thank you page
                        window.location.href = '/thank-you';
                      } else {
                        throw new Error('Booking creation failed');
                      }
                    } catch (error) {
                      console.error('Error creating free booking:', error);
                      alert(`Error: ${error.message}. Please try again.`);
                    }
                  }}
                  className="w-full bg-green-600 hover:bg-green-700 text-white py-3 px-4 rounded-md transition-colors font-medium"
                >
                  Complete Free Booking
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Payment;
