import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
// import { getStorage, storageRef } from 'firebase/storage'

const firebaseConfig = {
 apiKey: "AIzaSyCpKyBQZ1Fq-i_OufvyPblZ-jHDR7oEj_k",
  authDomain: "alex-albert.firebaseapp.com",
  projectId: "alex-albert",
  storageBucket: "alex-albert.firebasestorage.app",
  messagingSenderId: "682621507954",
  appId: "1:682621507954:web:6070aac6de862282d7e5eb",
  measurementId: "G-08Q8S7B0PP"
}

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const functions = getFunctions(app, 'us-central1');

// Uncomment this line for local development with Firebase emulators
// connectFunctionsEmulator(functions, "localhost", 5001);
