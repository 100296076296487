import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../firebase-config';
import { collection, addDoc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase-config';
import { loadStripe } from '@stripe/stripe-js';
import emailjs from '@emailjs/browser';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

// Custom payment form component that uses our Firebase function
const BookingPaymentForm = ({ formData, paymentAmount, onSuccess, onBypass, discountApplied }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  // Initialize payment when component mounts
  React.useEffect(() => {
    initializePayment();
  }, []);

  const initializePayment = async () => {
    setLoading(true);
    setError('');

    try {
      // Call the Firebase function to create a payment intent
      const createPaymentIntentFn = httpsCallable(functions, 'createPaymentIntent');
      
      const result = await createPaymentIntentFn({
        amount: paymentAmount, // Use the potentially discounted amount
        currency: 'gbp',
        description: 'Fitness Consultation Booking',
        metadata: {
          discountApplied: discountApplied || false
        }
      });
      
      // Get the client secret and publishable key from the response
      const { clientSecret, publishableKey } = result.data;
      
      // Initialize Stripe with the publishable key
      const stripeInstance = await loadStripe(publishableKey);
      setStripePromise(stripeInstance);
      
      // Set payment options with the client secret
      setPaymentOptions({
        clientSecret,
        appearance: {
          theme: 'stripe',
          variables: {
            colorPrimary: '#0a2540',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '4px',
            borderRadius: '4px',
          },
        },
      });
    } catch (err) {
      console.error('Error initializing payment:', err);
      setError(err.message || 'Failed to initialize payment. Please try again.');
      toast.error('Payment initialization failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center py-4">Initializing payment...</div>;
  }

  if (error) {
    return (
      <div className="text-center py-4">
        <p className="text-red-500 mb-2">{error}</p>
        <button 
          onClick={initializePayment}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Try Again
        </button>
      </div>
    );
  }

  if (!stripePromise || !paymentOptions) {
    return <div className="text-center py-4">Preparing payment form...</div>;
  }

  return (
    <div>
      <Elements stripe={stripePromise} options={paymentOptions}>
        <CheckoutForm 
          formData={formData} 
          clientSecret={paymentOptions.clientSecret} 
          onSuccess={onSuccess}
          onBypass={onBypass}
          paymentAmount={paymentAmount}
        />
      </Elements>
    </div>
  );
};

// Checkout form component
const CheckoutForm = ({ formData, clientSecret, onSuccess, onBypass, paymentAmount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return;
    }

    setIsProcessing(true);
    setPaymentError('');

    try {
      // Confirm the payment
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + '/thank-you',
        },
        redirect: 'if_required'
      });

      if (error) {
        setPaymentError(error.message);
        toast.error(`Payment failed: ${error.message}`);
        setIsProcessing(false);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        // Payment successful, call onSuccess to save booking
        onSuccess(paymentIntent.id);
      } else {
        // Handle other statuses
        toast.info('Payment processing. Please wait...');
        setIsProcessing(false);
      }
    } catch (err) {
      console.error('Payment error:', err);
      setPaymentError('An unexpected error occurred. Please try again.');
      toast.error('Payment processing error. Please try again.');
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-6">
        <PaymentElement />
        
        {paymentError && (
          <div className="mt-4 p-3 bg-red-100 text-red-700 rounded">
            {paymentError}
          </div>
        )}
      </div>
      
      <div className="flex space-x-4">
        <button
          type="submit"
          className="button-accept"
          disabled={!stripe || isProcessing}
        >
          {isProcessing ? 'Processing...' : `Submit and Pay ${paymentAmount.toFixed(2)}`}
        </button>
        {/* <button
          type="button"
          onClick={onBypass}
          className="button-warn"
        >
          Bypass Payment (Demo)
        </button> */}
      </div>
    </form>
  );
};

function Book() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    fitnessGoals: '',
    currentFitnessLevel: '',
    dietaryRestrictions: '',
    medicalConditions: '',
    preferredWorkoutDays: [],
    additionalNotes: '',
    discountCode: ''
  });
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(50); // Default consultation fee
  const [originalAmount] = useState(50); // Keep track of original amount
  const [appliedDiscount, setAppliedDiscount] = useState(null);
  const [discountError, setDiscountError] = useState('');
  const [isCheckingDiscount, setIsCheckingDiscount] = useState(false);
  const [stripeInitialized, setStripeInitialized] = useState(false);

  const steps = [
    'Personal Information',
    'Fitness Profile',
    'Health Information',
    'Preferences',
    'Review and Payment'
  ];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      const updatedWorkoutDays = checked
        ? [...formData.preferredWorkoutDays, value]
        : formData.preferredWorkoutDays.filter(day => day !== value);
      setFormData({ ...formData, preferredWorkoutDays: updatedWorkoutDays });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleDiscountCodeChange = (e) => {
    setFormData({ ...formData, discountCode: e.target.value.toUpperCase() });
    setDiscountError('');
    setStripeInitialized(false);
  };

  const applyDiscountCode = async () => {
    if (!formData.discountCode.trim()) {
      setDiscountError('Please enter a discount code');
      return;
    }

    setIsCheckingDiscount(true);
    setDiscountError('');
    setStripeInitialized(false);

    try {
      // Query Firestore for the discount code
      const discountQuery = query(
        collection(db, 'discountCodes'),
        where('code', '==', formData.discountCode.trim()),
        where('active', '==', true)
      );
      
      const querySnapshot = await getDocs(discountQuery);
      
      if (querySnapshot.empty) {
        setDiscountError('Invalid or expired discount code');
        setIsCheckingDiscount(false);
        return;
      }
      
      // Get the first matching discount code
      const discountData = querySnapshot.docs[0].data();
      
      // Check if the discount code has expired
      if (discountData.expiryDate && new Date() > discountData.expiryDate.toDate()) {
        setDiscountError('This discount code has expired');
        setIsCheckingDiscount(false);
        return;
      }
      
      // Apply the discount
      const discountPercentage = discountData.percentOff;
      const discountAmount = (originalAmount * discountPercentage) / 100;
      const discountedPrice = Math.max(originalAmount - discountAmount, 0);
      
      setPaymentAmount(discountedPrice);
      setAppliedDiscount({
        code: formData.discountCode,
        percentOff: discountPercentage,
        originalAmount,
        discountedAmount: discountedPrice
      });
      
      toast.success(`Discount code applied: ${discountPercentage}% off`);
    } catch (error) {
      console.error('Error applying discount code:', error);
      setDiscountError('Error applying discount code. Please try again.');
    } finally {
      setIsCheckingDiscount(false);
    }
  };

  const removeDiscount = () => {
    setPaymentAmount(originalAmount);
    setAppliedDiscount(null);
    setFormData({ ...formData, discountCode: '' });
    setStripeInitialized(false);
  };

  const handleNextStep = () => {
    const validationResult = validateStep();
    if (validationResult === true) {
      setCurrentStep(currentStep + 1);
    } else {
      toast.error(validationResult);
    }
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const validateStep = () => {
    switch (currentStep) {
      case 0:
        if (!formData.name) return "Please enter your name.";
        if (!formData.email) return "Please enter your email.";
        if (!/^\S+@\S+\.\S+$/.test(formData.email)) return "Please enter a valid email address.";
        if (!formData.phone) return "Please enter your phone number.";
        if (!/^(07\d{9})$/.test(formData.phone)) return "Please enter a valid UK mobile number starting with '07' (11 digits).";
        return true;
      case 1:
        if (!formData.fitnessGoals) return "Please enter your fitness goals.";
        if (!formData.currentFitnessLevel) return "Please select your current fitness level.";
        return true;
      case 2:
        return true; // These fields are optional
      case 3:
        if (formData.preferredWorkoutDays.length === 0) return "Please select at least one preferred workout day.";
        return true;
      default:
        return true;
    }
  };

  // Function to send confirmation email using EmailJS
  const sendConfirmationEmail = async (bookingData) => {
    try {
      // Prepare template parameters
      const templateParams = {
        name: bookingData.name,
        email: bookingData.email,
        phone: bookingData.phone,
        fitness_goals: bookingData.fitnessGoals,
        fitness_level: bookingData.currentFitnessLevel,
        dietary_restrictions: bookingData.dietaryRestrictions || 'None specified',
        medical_conditions: bookingData.medicalConditions || 'None specified',
        preferred_workout_days: bookingData.preferredWorkoutDays.join(', '),
        additional_notes: bookingData.additionalNotes || 'None',
        original_amount: bookingData.originalAmount.toFixed(2),
        final_amount: bookingData.amount.toFixed(2),
        payment_status: bookingData.paymentStatus === 'completed' ? 'Paid' : 'Free Booking',
      payment_date: new Date().toLocaleDateString('en-GB'),
        current_year: new Date().getFullYear(),
        // website_url: window.location.origin,
        // contact_email: 'support@yourfitnesscompany.com', // Replace with your actual support email
        // logo_url: `${window.location.origin}/logo192.png` // Update with your actual logo path
      };
      
      // Add discount information if applicable
      templateParams.discount_applied = bookingData.discountApplied || false;
      if (bookingData.discountApplied) {
        templateParams.discount_code = bookingData.discountCode;
        templateParams.discount_percentage = bookingData.discountPercentage;
        templateParams.discount_amount = (bookingData.originalAmount - bookingData.amount).toFixed(2);
      }
      
      // Send the email using EmailJS
      const response = await emailjs.send(
        'service_7qsg1lj', // Replace with your EmailJS service ID
        'template_7ljvb67', // Replace with your EmailJS template ID that uses the booking-confirmation-template.html
        templateParams,
        '4bH4AWhWPAKEmWvmQ' // Replace with your EmailJS public key
      );
      
      console.log('Email sent successfully:', response);
      return true;
    } catch (error) {
      console.error('Error sending confirmation email:', error);
      return false;
    }
  };

  const handlePaymentSuccess = async (paymentIntentId) => {
    setIsSubmitting(true);
    setError('');

    try {
      // Create booking document in Firestore
      const bookingData = {
        ...formData,
        preferredWorkoutDays: formData.preferredWorkoutDays,
        paymentIntentId: paymentIntentId,
        paymentStatus: 'completed',
        originalAmount: originalAmount,
        amount: paymentAmount,
        // discountApplied: appliedDiscount !== null,
        discountCode: appliedDiscount ? appliedDiscount.code : null,
        discountPercentage: appliedDiscount ? appliedDiscount.percentOff : null,
        currency: 'GBP',
        timestamp: serverTimestamp(),
        type: 'paid',
        flowSource: 'consultation-intro'
      };
      
      const docRef = await addDoc(collection(db, "bookings"), bookingData);
      
      // Send confirmation email
      await sendConfirmationEmail({
        ...bookingData,
        id: docRef.id
      });

      navigate('/thank-you');
    } catch (error) {
      console.error('Error saving booking:', error);
      setError('Payment was successful, but there was an error saving your booking. Please contact us.');
      toast.error('Error saving booking. Please contact us with your payment confirmation.');
      setIsSubmitting(false);
    }
  };

  const handleBypass = async () => {
    try {
      setIsSubmitting(true);
      setError('');

      // Final validation
      for (let i = 0; i < steps.length; i++) {
        const validationResult = validateStep(i);
        if (validationResult !== true) {
          toast.error(`Step ${i + 1}: ${validationResult}`);
          setIsSubmitting(false);
          return;
        }
      }

      // Create booking document in Firestore for bypass
      const bookingData = {
        ...formData,
        preferredWorkoutDays: formData.preferredWorkoutDays,
        paymentStatus: 'bypassed',
        originalAmount: originalAmount,
        amount: paymentAmount,
        discountApplied: appliedDiscount !== null,
        discountCode: appliedDiscount ? appliedDiscount.code : null,
        discountPercentage: appliedDiscount ? appliedDiscount.percentOff : null,
        currency: 'GBP',
        timestamp: serverTimestamp(),
        type: 'demo',
        flowSource: 'consultation-intro'
      };
      
      const docRef = await addDoc(collection(db, "bookings"), bookingData);
      
      // Send confirmation email
      await sendConfirmationEmail({
        ...bookingData,
        id: docRef.id
      });

      console.log("Document written with ID: ", docRef.id);
      navigate('/thank-you');
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
      toast.error('An error occurred while processing your booking. Please try again.');
      setIsSubmitting(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Personal Information</h2>
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2 font-bold">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block mb-2 font-bold">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="block mb-2 font-bold">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                placeholder="07xxxxxxxxx"
                required
              />
              <p className="text-sm text-gray-600 mt-1">Enter a UK mobile number starting with '07' (11 digits)</p>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Fitness Profile</h2>
            <div className="mb-4">
              <label htmlFor="fitnessGoals" className="block mb-2 font-bold">Fitness Goals</label>
              <textarea
                id="fitnessGoals"
                name="fitnessGoals"
                value={formData.fitnessGoals}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                required
              ></textarea>
            </div>
            <div className="mb-4">
              <label htmlFor="currentFitnessLevel" className="block mb-2 font-bold">Current Fitness Level</label>
              <select
                id="currentFitnessLevel"
                name="currentFitnessLevel"
                value={formData.currentFitnessLevel}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                required
              >
                <option value="">Select your fitness level</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </select>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Health Information</h2>
            <div className="mb-4">
              <label htmlFor="dietaryRestrictions" className="block mb-2 font-bold">Dietary Restrictions</label>
              <textarea
                id="dietaryRestrictions"
                name="dietaryRestrictions"
                value={formData.dietaryRestrictions}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              ></textarea>
            </div>
            <div className="mb-4">
              <label htmlFor="medicalConditions" className="block mb-2 font-bold">Medical Conditions</label>
              <textarea
                id="medicalConditions"
                name="medicalConditions"
                value={formData.medicalConditions}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              ></textarea>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Preferences</h2>
            <div className="mb-4">
              <label className="block mb-2 font-bold">Preferred Workout Days</label>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                <label key={day} className="inline-flex items-center mr-4 mb-2">
                  <input
                    type="checkbox"
                    name="preferredWorkoutDays"
                    value={day}
                    checked={formData.preferredWorkoutDays.includes(day)}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  {day}
                </label>
              ))}
            </div>
            <div className="mb-4">
              <label htmlFor="additionalNotes" className="block mb-2 font-bold">Additional Notes</label>
              <textarea
                id="additionalNotes"
                name="additionalNotes"
                value={formData.additionalNotes}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              ></textarea>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Review and Payment</h2>
            <div className="mb-4">
              <h3 className="text-xl font-semibold mb-2">Summary</h3>
              <p><strong>Name:</strong> {formData.name}</p>
              <p><strong>Email:</strong> {formData.email}</p>
              <p><strong>Phone:</strong> {formData.phone}</p>
              <p><strong>Fitness Goals:</strong> {formData.fitnessGoals}</p>
              <p><strong>Current Fitness Level:</strong> {formData.currentFitnessLevel}</p>
              <p><strong>Dietary Restrictions:</strong> {formData.dietaryRestrictions || 'None'}</p>
              <p><strong>Medical Conditions:</strong> {formData.medicalConditions || 'None'}</p>
              <p><strong>Preferred Workout Days:</strong> {formData.preferredWorkoutDays.join(', ')}</p>
              <p><strong>Additional Notes:</strong> {formData.additionalNotes || 'None'}</p>
            </div>
            <div className="mb-4">
              <h3 className="text-xl font-semibold mb-2">Payment</h3>
              <div className="mb-4">
                <p className="mb-2">Consultation Fee: <strong>£{originalAmount}</strong></p>
                
                {/* Discount Code Section */}
                <div className="discount-code-section mb-4">
                  <h4 className="text-lg font-medium mb-2">Discount Code</h4>
                  
                  {appliedDiscount ? (
                    <div className="applied-discount bg-green-50 border border-green-200 rounded-md p-3 mb-3">
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="text-green-800 font-medium">{appliedDiscount.code} ({appliedDiscount.percentOff}% off)</p>
                          <p className="text-sm text-gray-600">
                            Original: £{appliedDiscount.originalAmount.toFixed(2)} → 
                            Discounted: £{appliedDiscount.discountedAmount.toFixed(2)}
                          </p>
                        </div>
                        <button
                          type="button"
                          onClick={removeDiscount}
                          className="text-red-600 hover:text-red-800 text-sm"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="discount-input-group flex">
                      <input
                        type="text"
                        name="discountCode"
                        value={formData.discountCode}
                        onChange={handleDiscountCodeChange}
                        placeholder="Enter discount code"
                        className="flex-grow px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:shadow-outline"
                      />
                      <button
                        type="button"
                        onClick={applyDiscountCode}
                        disabled={isCheckingDiscount || !formData.discountCode.trim()}
                        className="bg-gray-200 hover:bg-gray-300 text-gray-800 px-4 py-2 rounded-r-md transition-colors disabled:opacity-50"
                      >
                        {isCheckingDiscount ? 'Checking...' : 'Apply'}
                      </button>
                    </div>
                  )}
                  
                  {discountError && (
                    <p className="text-red-600 text-sm mt-1">{discountError}</p>
                  )}
                </div>
                
                <p className="font-bold text-lg">
                  Total to Pay: <span className={appliedDiscount ? "text-green-600" : ""}>£{paymentAmount.toFixed(2)}</span>
                </p>
              </div>
              
              {paymentAmount > 0 ? (
                <>
                  {!stripeInitialized ? (
                    <div className="mb-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
                      <h3 className="text-lg font-semibold mb-2">Payment Summary</h3>
                      <p className="mb-1"><strong>Amount:</strong> £{originalAmount.toFixed(2)}</p>
                      {appliedDiscount && (
                        <p className="mb-1 text-green-600">
                          <strong>Discount:</strong> {appliedDiscount.percentOff}% off ({appliedDiscount.code})
                        </p>
                      )}
                      <p className="text-lg font-bold mt-2">
                        Total: £{paymentAmount.toFixed(2)}
                      </p>
                      <button
                        type="button"
                        onClick={() => {
                          // Initialize Stripe payment with the current amount
                          setStripeInitialized(true);
                        }}
                        className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors font-medium"
                      >
                        Confirm and Proceed to Payment
                      </button>
                    </div>
                  ) : (
                    <BookingPaymentForm 
                      formData={formData}
                      paymentAmount={paymentAmount}
                      onSuccess={handlePaymentSuccess}
                      onBypass={handleBypass}
                      discountApplied={appliedDiscount !== null}
                    />
                  )}
                </>
              ) : (
                <div className="free-booking-container bg-green-50 p-4 rounded-lg border border-green-200">
                  <h3 className="text-xl font-semibold mb-2 text-green-800">Free Booking</h3>
                  <p className="mb-4">
                    Your discount code has reduced the total to £0. You can complete your booking without payment.
                  </p>
                  <button
                    type="button"
                    onClick={handleBypass}
                    className="w-full bg-green-600 hover:bg-green-700 text-white py-3 px-4 rounded-md transition-colors font-medium"
                  >
                    Complete Free Booking
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-4 mt-24 py-8">
      <h1 className="text-3xl font-bold mb-6">Book a Fitness Consultation</h1>
      <div className="mb-6">
        {steps.map((step, index) => (
          <span
            key={index}
            className={`inline-block mr-2 px-3 py-1 rounded-full font-bold ${
              index === currentStep ? 'bg-blue-500 text-white' : 'bg-gray-200'
            }`}
          >
            {index + 1}
          </span>
        ))}
      </div>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {renderStep()}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="mt-6">
          {currentStep > 0 && (
            <button
              type="button"
              onClick={handlePrevStep}
              className="button-decline"
            >
              Previous
            </button>
          )}
          {currentStep < steps.length - 1 && (
            <button
              type="button"
              onClick={handleNextStep}
              className="button-accept"
            >
              Next
            </button>
          )}
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default Book;
