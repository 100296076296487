import React from 'react';
import { useNavigate } from 'react-router-dom';
import aboutImage from '../skin/images/ConsultationHero.jpg';
import strengthImage from '../skin/images/optimized/CompleteYourForm.jpg';
import weightImage from '../skin/images/optimized/BookandPay.png';
import injuryImage from '../skin/images/optimized/MeetYourTrainer.jpg';

function ConsultationIntro() {
  const navigate = useNavigate();

  const handleStartBooking = () => {
    navigate('/booking-form');
  };

  return (
    <div className="container mx-auto px-4 mt-24 py-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="relative mb-8">
          <img 
            src={aboutImage} 
            alt="Fitness Consultation" 
            className="w-full h-64 object-cover rounded-lg"
          />
          <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center rounded-lg">
            <h1 className="text-4xl font-bold text-white text-center px-4">Fitness Consultation</h1>
          </div>
        </div>
        
        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">What to Expect</h2>
          <p className="mb-4">
            The fitness consultation is designed to help understand your unique needs, goals, and current fitness level. 
            This personalized approach allows Alex to create a tailored fitness plan that works specifically for you.
          </p>
          <p className="mb-4">
            During the consultation, we'll discuss your fitness history, goals, any health concerns, and preferences. 
            This information helps Alex develop a program that aligns with your lifestyle and objectives.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">The Process</h2>
          <div className="flex flex-col md:flex-row gap-6 mb-4">
            <div className="flex-1 border rounded-lg overflow-hidden bg-gray-50">
              <div className="h-40 overflow-hidden">
                <img src={strengthImage} alt="Complete the Form" className="w-full h-full object-cover" />
              </div>
              <div className="p-4">
                <h3 className="font-bold text-lg mb-2">1. Complete the Form</h3>
                <p>Fill out the comprehensive questionnaire about your personal information, fitness goals, health history, and preferences.</p>
              </div>
            </div>
            <div className="flex-1 border rounded-lg overflow-hidden bg-gray-50">
              <div className="h-40 overflow-hidden">
                <img src={weightImage} alt="Book & Pay" className="w-full h-full object-cover" />
              </div>
              <div className="p-4">
                <h3 className="font-bold text-lg mb-2">2. Book & Pay</h3>
                <p>Secure your consultation with a £50 booking fee.</p>
              </div>
            </div>
            <div className="flex-1 border rounded-lg overflow-hidden bg-gray-50">
              <div className="h-40 overflow-hidden">
                <img src={injuryImage} alt="Meet Your Trainer" className="w-full h-full object-cover" />
              </div>
              <div className="p-4">
                <h3 className="font-bold text-lg mb-2">3. Meet Your Trainer</h3>
                <p>Once booked you'll be able to schedule a time directly in Alex's diary to have a one-on-one call to discuss your plan and how Alex can work with you to get to your goals.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Benefits</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><p>Personalized fitness assessment</p></li>
            <li><p>Expert guidance from certified trainers</p></li>
            <li><p>Custom workout plans based on your goals</p></li>
            <li><p>Nutritional advice tailored to your needs</p></li>
            <li><p>Strategies to overcome fitness plateaus</p></li>
            <li><p>Injury prevention techniques</p></li>
          </ul>
        </div>

        <div className="text-center mt-8">
          <button 
            onClick={handleStartBooking}
            className="button-accept text-lg px-8 py-3"
          >
            Start Booking Process
          </button>
          <p className="mt-4 text-sm text-gray-600">
            The consultation booking requires a £50 fee.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ConsultationIntro;
