import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase-config'

// Helper function to format video URLs for embedding
const formatVideoUrl = (url) => {
  if (!url) return null;
  
  // Handle Google Drive URLs
  if (url.includes('drive.google.com')) {
    // Extract the file ID from the URL
    const fileIdMatch = url.match(/[-\w]{25,}/);
    if (fileIdMatch && fileIdMatch[0]) {
      const fileId = fileIdMatch[0];
      return `https://drive.google.com/file/d/${fileId}/preview`;
    }
  }
  
  // Handle YouTube URLs
  if (url.includes('youtube.com') || url.includes('youtu.be')) {
    let videoId;
    
    // Full youtube.com URL
    if (url.includes('youtube.com/watch')) {
      const urlParams = new URLSearchParams(url.split('?')[1]);
      videoId = urlParams.get('v');
    } 
    // Shortened youtu.be URL
    else if (url.includes('youtu.be')) {
      videoId = url.split('/').pop();
    }
    
    if (videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    }
  }
  
  // Return the original URL if we couldn't format it
  return url;
}

function Workouts() {
  const [workouts, setWorkouts] = useState([])
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState({
    difficulty: 'all',
    category: 'all'
  })

  useEffect(() => {
    const fetchWorkouts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'workouts'))
        const workoutsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        setWorkouts(workoutsList)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching workouts:', error)
        setLoading(false)
      }
    }

    fetchWorkouts()
  }, [])

  const filteredWorkouts = workouts.filter(workout => {
    return (filter.difficulty === 'all' || workout.difficulty === filter.difficulty) &&
           (filter.category === 'all' || workout.category === filter.category)
  })

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    setFilter(prev => ({
      ...prev,
      [name]: value
    }))
  }

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-16 flex justify-center">
        <div className="text-xl">Loading workouts...</div>
      </div>
    )
  }

  return (
    <div className="container mx-auto px-4 py-16 pt-32">
      <h1 className="text-4xl font-bold mb-8 text-center">Free Workouts</h1>
      
      {workouts.length === 0 ? (
        <div className="text-center text-gray-500">
          <p>No workouts available yet. Check back soon!</p>
        </div>
      ) : (
        <>
          {/* Filters */}
          <div className="bg-gray-100 p-4 rounded-lg mb-8">
            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Difficulty
                </label>
                <select
                  name="difficulty"
                  value={filter.difficulty}
                  onChange={handleFilterChange}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2"
                >
                  <option value="all">All Difficulties</option>
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="advanced">Advanced</option>
                </select>
              </div>
              
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Category
                </label>
                <select
                  name="category"
                  value={filter.category}
                  onChange={handleFilterChange}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2"
                >
                  <option value="all">All Categories</option>
                  <option value="strength">Strength</option>
                  <option value="cardio">Cardio</option>
                  <option value="flexibility">Flexibility</option>
                  <option value="hiit">HIIT</option>
                  <option value="recovery">Recovery</option>
                </select>
              </div>
            </div>
          </div>

          {/* Workouts Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredWorkouts.length === 0 ? (
              <div className="col-span-full text-center text-gray-500 py-8">
                No workouts match your selected filters.
              </div>
            ) : (
              filteredWorkouts.map(workout => (
                <Link 
                  to={`/workouts/${workout.id}`} 
                  key={workout.id} 
                  className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
                >
                  {workout.videoUrl && (
                    <div className="aspect-w-16 aspect-h-9">
                      <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                        <div className="text-gray-500 flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="p-6">
                    <h2 className="text-xl font-bold mb-2">{workout.title}</h2>
                    <p className="text-gray-600 mb-4 line-clamp-2">{workout.description}</p>
                    
                    <div className="flex flex-wrap gap-2 mb-4">
                      <span className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">
                        {workout.difficulty}
                      </span>
                      <span className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded">
                        {workout.duration} min
                      </span>
                      <span className="bg-purple-100 text-purple-800 text-xs px-2 py-1 rounded">
                        {workout.category}
                      </span>
                    </div>
                    
                    <div className="text-indigo-600 font-medium">
                      View workout details &rarr;
                    </div>
                  </div>
                </Link>
              ))
            )}
          </div>
          
          {/* Call to Action Banner */}
          <div className="bg-indigo-100 rounded-lg p-6 mt-12">
            <div className="max-w-3xl mx-auto text-center">
              <h3 className="text-2xl font-bold text-indigo-800 mb-3">
                Need a personalised workout plan?
              </h3>
              <p className="text-indigo-700 mb-6 max-w-2xl mx-auto">
                Alex can create a customised workout plan tailored to your specific needs, goals, and fitness level.
              </p>
              <Link 
                to="/book-consultation" 
                className="inline-block bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-8 rounded-lg transition duration-200"
              >
                Book a Consultation
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Workouts
