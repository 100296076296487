import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../firebase-config'

function Admin() {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('workouts')
  const [workouts, setWorkouts] = useState([])
  const [consultations, setConsultations] = useState([])
  const [showCompleted, setShowCompleted] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedConsultation, setSelectedConsultation] = useState(null)
  const [discountCodes, setDiscountCodes] = useState([])
  const [newDiscountCode, setNewDiscountCode] = useState({
    code: '',
    percentOff: 10,
    active: true,
    expiryDate: ''
  })
  const [newWorkout, setNewWorkout] = useState({
    title: '',
    description: '',
    difficulty: 'beginner',
    duration: '',
    category: 'strength',
    videoUrl: ''
  })
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
      setLoading(false)
      if (!currentUser) navigate('/login')
    })

    return () => unsubscribe()
  }, [navigate])

  useEffect(() => {
    if (user) {
      fetchWorkouts()
      fetchConsultations()
      fetchDiscountCodes()
    }
  }, [user])

  const fetchWorkouts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'workouts'))
      const workoutsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      setWorkouts(workoutsList)
    } catch (error) {
      console.error('Error fetching workouts:', error)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewWorkout(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    try {
      await addDoc(collection(db, 'workouts'), {
        ...newWorkout,
        createdAt: new Date(),
        createdBy: user.email
      })
      
      // Reset form
      setNewWorkout({
        title: '',
        description: '',
        difficulty: 'beginner',
        duration: '',
        category: 'strength',
        videoUrl: ''
      })
      
      // Refresh workouts list
      fetchWorkouts()
    } catch (error) {
      console.error('Error adding workout:', error)
    }
  }

  const handleDeleteWorkout = async (id) => {
    if (window.confirm('Are you sure you want to delete this workout?')) {
      try {
        await deleteDoc(doc(db, 'workouts', id))
        fetchWorkouts()
      } catch (error) {
        console.error('Error deleting workout:', error)
      }
    }
  }

  const fetchConsultations = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'bookings'))
      const consultationsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date()
      }))
      // Sort consultations by timestamp in descending order (latest first)
      const sortedConsultations = consultationsList.sort((a, b) => 
        b.timestamp - a.timestamp
      )
      setConsultations(sortedConsultations)
    } catch (error) {
      console.error('Error fetching consultations:', error)
    }
  }

  const fetchDiscountCodes = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'discountCodes'))
      const discountCodesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        expiryDate: doc.data().expiryDate?.toDate() || null
      }))
      setDiscountCodes(discountCodesList)
    } catch (error) {
      console.error('Error fetching discount codes:', error)
    }
  }

  const handleDiscountCodeInputChange = (e) => {
    const { name, value } = e.target
    setNewDiscountCode(prev => ({
      ...prev,
      [name]: name === 'percentOff' ? parseInt(value, 10) : value
    }))
  }

  const handleDiscountCodeActiveChange = (e) => {
    setNewDiscountCode(prev => ({
      ...prev,
      active: e.target.checked
    }))
  }

  const handleAddDiscountCode = async (e) => {
    e.preventDefault()
    
    try {
      // Convert expiryDate string to Date object if it exists
      const expiryDate = newDiscountCode.expiryDate 
        ? new Date(newDiscountCode.expiryDate) 
        : null
      
      await addDoc(collection(db, 'discountCodes'), {
        ...newDiscountCode,
        expiryDate,
        createdAt: new Date()
      })
      
      // Reset form
      setNewDiscountCode({
        code: '',
        percentOff: 10,
        active: true,
        expiryDate: ''
      })
      
      // Refresh discount codes list
      fetchDiscountCodes()
    } catch (error) {
      console.error('Error adding discount code:', error)
    }
  }

  const handleDeleteDiscountCode = async (id) => {
    if (window.confirm('Are you sure you want to delete this discount code?')) {
      try {
        await deleteDoc(doc(db, 'discountCodes', id))
        fetchDiscountCodes()
      } catch (error) {
        console.error('Error deleting discount code:', error)
      }
    }
  }

  const handleToggleDiscountCodeStatus = async (id, currentStatus) => {
    try {
      await updateDoc(doc(db, 'discountCodes', id), {
        active: !currentStatus
      })
      fetchDiscountCodes()
    } catch (error) {
      console.error('Error updating discount code status:', error)
    }
  }

  const handleMarkCompleted = async (id) => {
    try {
      await updateDoc(doc(db, 'bookings', id), {
        status: 'completed',
        completedAt: new Date()
      })
      fetchConsultations()
    } catch (error) {
      console.error('Error marking consultation as completed:', error)
    }
  }

  const handleLogout = () => {
    auth.signOut()
    navigate('/login')
  }
  
  const openDetailsModal = (consultation) => {
    setSelectedConsultation(consultation)
    setModalOpen(true)
  }
  
  const closeModal = () => {
    setModalOpen(false)
    setSelectedConsultation(null)
  }

  if (loading) return <div className="flex justify-center items-center h-screen">Loading...</div>

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Admin Dashboard</h1>
        <button 
          onClick={handleLogout}
          className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
        >
          Logout
        </button>
      </div>

      <div className="mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            <button
              onClick={() => setActiveTab('workouts')}
              className={`py-4 px-6 text-center border-b-2 font-medium text-sm ${
                activeTab === 'workouts'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Workouts
            </button>
            <button
              onClick={() => setActiveTab('consultations')}
              className={`py-4 px-6 text-center border-b-2 font-medium text-sm ${
                activeTab === 'consultations'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Consultations
            </button>
            <button
              onClick={() => setActiveTab('discountCodes')}
              className={`py-4 px-6 text-center border-b-2 font-medium text-sm ${
                activeTab === 'discountCodes'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Discount Codes
            </button>
          </nav>
        </div>
      </div>

      {activeTab === 'workouts' && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Workout Form */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Add New Workout</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={newWorkout.title}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={newWorkout.description}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                rows="4"
                required
              ></textarea>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="difficulty">
                Difficulty
              </label>
              <select
                id="difficulty"
                name="difficulty"
                value={newWorkout.difficulty}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="duration">
                Duration (minutes)
              </label>
              <input
                type="number"
                id="duration"
                name="duration"
                value={newWorkout.duration}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
                Category
              </label>
              <select
                id="category"
                name="category"
                value={newWorkout.category}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="strength">Strength</option>
                <option value="cardio">Cardio</option>
                <option value="flexibility">Flexibility</option>
                <option value="hiit">HIIT</option>
                <option value="recovery">Recovery</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="videoUrl">
                Video URL
              </label>
              <input
                type="text"
                id="videoUrl"
                name="videoUrl"
                value={newWorkout.videoUrl}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Google Drive or YouTube URL"
              />
              <p className="text-xs text-gray-500 mt-1">
                For Google Drive videos, use the sharing link with "Anyone with the link can view" permission
              </p>
            </div>

            <button
              type="submit"
              className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            >
              Add Workout
            </button>
          </form>
        </div>

        {/* Workouts List */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Existing Workouts</h2>
          {workouts.length === 0 ? (
            <p className="text-gray-500">No workouts added yet.</p>
          ) : (
            <div className="space-y-4">
              {workouts.map((workout) => (
                <div key={workout.id} className="border-b pb-4">
                  <div className="flex justify-between items-start">
                    <h3 className="font-bold">{workout.title}</h3>
                    <button
                      onClick={() => handleDeleteWorkout(workout.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">{workout.description}</p>
                  <div className="flex flex-wrap gap-2 mt-2">
                    <span className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">
                      {workout.difficulty}
                    </span>
                    <span className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded">
                      {workout.duration} min
                    </span>
                    <span className="bg-purple-100 text-purple-800 text-xs px-2 py-1 rounded">
                      {workout.category}
                    </span>
                  </div>
                  {workout.videoUrl && (
                    <div className="mt-2">
                      <span className="text-xs text-gray-500">Video: </span>
                      <a 
                        href={workout.videoUrl} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-xs text-blue-600 hover:underline truncate inline-block max-w-xs"
                      >
                        {workout.videoUrl}
                      </a>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        </div>
      )}

      {activeTab === 'consultations' && (
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Booked Consultations</h2>
            <div className="flex items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input 
                  type="checkbox" 
                  checked={showCompleted} 
                  onChange={() => setShowCompleted(!showCompleted)} 
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                <span className="ml-3 text-sm font-medium text-gray-700">Show Completed</span>
              </label>
            </div>
          </div>
          {consultations.length === 0 ? (
            <p className="text-gray-500">No consultations booked yet.</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Contact
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {consultations
                    .filter(consultation => showCompleted || consultation.status !== 'completed')
                    .map((consultation) => (
                    <tr key={consultation.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">{consultation.name}</div>
                        <div className="text-sm text-gray-500 line-clamp-2">
                          {consultation.fitnessGoals}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{consultation.email}</div>
                        <div className="text-sm text-gray-500">{consultation.phone}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {consultation.timestamp.toLocaleDateString()}
                        </div>
                        <div className="text-sm text-gray-500">
                          {consultation.timestamp.toLocaleTimeString()}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {consultation.amount 
                            ? `£${consultation.amount.toFixed(2)}` 
                            : 'N/A'}
                        </div>
                        {consultation.discountApplied && (
                          <div className="text-xs text-green-600">
                            Discount: {consultation.discountApplied.code}
                          </div>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          consultation.status === 'completed' 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {consultation.status === 'completed' ? 'Completed' : 'Pending'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        {(!consultation.status || consultation.status !== 'completed') && (
                          <button
                            onClick={() => handleMarkCompleted(consultation.id)}
                            className="text-indigo-600 hover:text-indigo-900 mr-4"
                          >
                            Mark Completed
                          </button>
                        )}
                        <button
                          onClick={() => openDetailsModal(consultation)}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {activeTab === 'discountCodes' && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Discount Code Form */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Add New Discount Code</h2>
            <form onSubmit={handleAddDiscountCode}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="code">
                  Code
                </label>
                <input
                  type="text"
                  id="code"
                  name="code"
                  value={newDiscountCode.code}
                  onChange={handleDiscountCodeInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                  placeholder="e.g. SUMMER20"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="percentOff">
                  Discount Percentage
                </label>
                <input
                  type="number"
                  id="percentOff"
                  name="percentOff"
                  min="1"
                  max="100"
                  value={newDiscountCode.percentOff}
                  onChange={handleDiscountCodeInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
                <p className="text-xs text-gray-500 mt-1">
                  Enter a value between 1 and 100
                </p>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expiryDate">
                  Expiry Date (Optional)
                </label>
                <input
                  type="date"
                  id="expiryDate"
                  name="expiryDate"
                  value={newDiscountCode.expiryDate}
                  onChange={handleDiscountCodeInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="mb-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={newDiscountCode.active}
                    onChange={handleDiscountCodeActiveChange}
                    className="mr-2"
                  />
                  <span className="text-gray-700 text-sm font-bold">Active</span>
                </label>
              </div>

              <button
                type="submit"
                className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              >
                Add Discount Code
              </button>
            </form>
          </div>

          {/* Discount Codes List */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Existing Discount Codes</h2>
            {discountCodes.length === 0 ? (
              <p className="text-gray-500">No discount codes added yet.</p>
            ) : (
              <div className="space-y-4">
                {discountCodes.map((discountCode) => (
                  <div key={discountCode.id} className="border-b pb-4">
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="font-bold">{discountCode.code}</h3>
                        <p className="text-sm text-gray-600 mt-1">{discountCode.percentOff}% off</p>
                        {discountCode.expiryDate && (
                          <p className="text-sm text-gray-600">
                            Expires: {discountCode.expiryDate.toLocaleDateString()}
                          </p>
                        )}
                      </div>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleToggleDiscountCodeStatus(discountCode.id, discountCode.active)}
                          className={`text-sm px-2 py-1 rounded ${
                            discountCode.active 
                              ? 'bg-green-100 text-green-800 hover:bg-green-200' 
                              : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                          }`}
                        >
                          {discountCode.active ? 'Active' : 'Inactive'}
                        </button>
                        <button
                          onClick={() => handleDeleteDiscountCode(discountCode.id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      
      {/* Details Modal */}
      {modalOpen && selectedConsultation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <h3 className="text-xl font-bold">{selectedConsultation.name}</h3>
                <button 
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <h4 className="font-semibold text-gray-700 mb-1">Contact Information</h4>
                  <p className="text-sm mb-1">Email: {selectedConsultation.email}</p>
                  <p className="text-sm mb-1">Phone: {selectedConsultation.phone || 'Not provided'}</p>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-700 mb-1">Booking Details</h4>
                  <p className="text-sm mb-1">Date: {selectedConsultation.timestamp.toLocaleDateString()}</p>
                  <p className="text-sm mb-1">Time: {selectedConsultation.timestamp.toLocaleTimeString()}</p>
                  <p className="text-sm mb-1">Status: <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    selectedConsultation.status === 'completed' 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {selectedConsultation.status === 'completed' ? 'Completed' : 'Pending'}
                  </span></p>
                  {selectedConsultation.amount && (
                    <p className="text-sm mb-1">Amount: £{selectedConsultation.amount.toFixed(2)}</p>
                  )}
                </div>
              </div>
              
              <div className="mb-4">
                <h4 className="font-semibold text-gray-700 mb-1">Fitness Profile</h4>
                <p className="text-sm mb-1">Current Level: {selectedConsultation.currentFitnessLevel || 'Not specified'}</p>
                <p className="text-sm mb-1">Preferred Workout Days: {selectedConsultation.preferredWorkoutDays?.join(', ') || 'Not specified'}</p>
              </div>
              
              <div className="mb-4">
                <h4 className="font-semibold text-gray-700 mb-1">Fitness Goals</h4>
                <p className="text-sm">{selectedConsultation.fitnessGoals || 'None specified'}</p>
              </div>
              
              <div className="mb-4">
                <h4 className="font-semibold text-gray-700 mb-1">Dietary Restrictions</h4>
                <p className="text-sm">{selectedConsultation.dietaryRestrictions || 'None'}</p>
              </div>
              
              <div className="mb-4">
                <h4 className="font-semibold text-gray-700 mb-1">Medical Conditions</h4>
                <p className="text-sm">{selectedConsultation.medicalConditions || 'None'}</p>
              </div>
              
              <div className="mb-4">
                <h4 className="font-semibold text-gray-700 mb-1">Additional Notes</h4>
                <p className="text-sm">{selectedConsultation.additionalNotes || 'None'}</p>
              </div>
              
              <div className="flex justify-end mt-6">
                <button
                  onClick={closeModal}
                  className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                >
                  Close
                </button>
                {(!selectedConsultation.status || selectedConsultation.status !== 'completed') && (
                  <button
                    onClick={() => {
                      handleMarkCompleted(selectedConsultation.id);
                      closeModal();
                    }}
                    className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded ml-2"
                  >
                    Mark as Completed
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Admin
