import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Payment form component
const PaymentForm = ({ clientSecret, amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return;
    }

    setIsProcessing(true);
    setPaymentError('');

    try {
      // Confirm the payment
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + '/thank-you',
        },
        redirect: 'if_required'
      });

      if (error) {
        setPaymentError(error.message);
        toast.error(`Payment failed: ${error.message}`);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        toast.success('Payment successful!');
        // Navigate to thank you page after successful payment
        setTimeout(() => navigate('/thank-you'), 2000);
      } else {
        // Handle other statuses or implement polling for status changes
        toast.info('Payment processing. Please wait...');
      }
    } catch (err) {
      console.error('Payment error:', err);
      setPaymentError('An unexpected error occurred. Please try again.');
      toast.error('Payment processing error. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <div className="form-group">
        <label>Payment Amount: £{amount}</label>
      </div>
      
      <PaymentElement />
      
      {paymentError && (
        <div className="error-message">
          {paymentError}
        </div>
      )}
      
      <button 
        type="submit" 
        disabled={!stripe || isProcessing}
        className="payment-button"
      >
        {isProcessing ? 'Processing...' : `Pay £${amount}`}
      </button>
    </form>
  );
};

// Main Stripe Payment component
const StripePayment = ({ 
  amount = 50, 
  currency = 'gbp', 
  description = 'Workout Session',
  discountApplied = false
}) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const initializePayment = async () => {
    setLoading(true);
    setError('');

    try {
      // Get the current user's ID token for authentication (if logged in)
      const auth = getAuth();
      let idToken = '';
      
      if (auth.currentUser) {
        idToken = await auth.currentUser.getIdToken();
      }
      
      // Call the Firebase function directly via REST API
      const response = await fetch('https://us-central1-alex-albert.cloudfunctions.net/createPaymentIntentHttp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': idToken ? `Bearer ${idToken}` : '',
          // Add CORS headers
          'Origin': window.location.origin,
        },
        body: JSON.stringify({
          data: {
            amount,
            currency,
            description,
            metadata: {
              discountApplied
            }
          }
        })
      });
      
      if (!response.ok) {
        throw new Error(`Payment initialization failed: ${response.statusText}`);
      }
      
      const result = await response.json();
      
      // Get the client secret and publishable key from the response
      const { clientSecret, publishableKey } = result.result;
      
      // Initialize Stripe with the publishable key
      const stripeInstance = await loadStripe(publishableKey);
      setStripePromise(stripeInstance);
      
      // Set payment options with the client secret
      setPaymentOptions({
        clientSecret,
        appearance: {
          theme: 'stripe',
          variables: {
            colorPrimary: '#0a2540',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '4px',
            borderRadius: '4px',
          },
        },
      });
    } catch (err) {
      console.error('Error initializing payment:', err);
      setError(err.message || 'Failed to initialize payment. Please try again.');
      toast.error('Payment initialization failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Initialize payment when component mounts
  React.useEffect(() => {
    initializePayment();
  }, []);

  if (loading) {
    return <div className="loading">Initializing payment...</div>;
  }

  if (error) {
    return (
      <div className="error-container">
        <h3>Payment Error</h3>
        <p>{error}</p>
        <button onClick={initializePayment}>Try Again</button>
        <ToastContainer position="top-right" autoClose={5000} />
      </div>
    );
  }

  return (
    <div className="stripe-payment-container">
      <h2>Complete Your Payment {discountApplied && <span className="text-sm text-green-600">(Discount Applied)</span>}</h2>
      
      {stripePromise && paymentOptions ? (
        <Elements stripe={stripePromise} options={paymentOptions}>
          <PaymentForm clientSecret={paymentOptions.clientSecret} amount={amount} />
        </Elements>
      ) : (
        <div className="loading">Preparing payment form...</div>
      )}
      
      <ToastContainer position="top-right" autoClose={5000} />
      
      <style jsx>{`
        .stripe-payment-container {
          max-width: 500px;
          margin: 0 auto;
          padding: 20px;
          background-color: #f8f9fa;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
        
        h2 {
          text-align: center;
          margin-bottom: 20px;
          color: #0a2540;
        }
        
        .payment-form {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        
        .form-group {
          margin-bottom: 15px;
        }
        
        label {
          display: block;
          margin-bottom: 8px;
          font-weight: 600;
        }
        
        .error-message {
          color: #df1b41;
          margin-top: 10px;
          padding: 10px;
          background-color: rgba(223, 27, 65, 0.1);
          border-radius: 4px;
        }
        
        .payment-button {
          background-color: #0a2540;
          color: white;
          border: none;
          padding: 12px 20px;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        
        .payment-button:hover {
          background-color: #0d3359;
        }
        
        .payment-button:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
        
        .loading {
          text-align: center;
          padding: 20px;
          color: #666;
        }
        
        .error-container {
          text-align: center;
          padding: 20px;
        }
        
        .error-container button {
          background-color: #0a2540;
          color: white;
          border: none;
          padding: 10px 15px;
          border-radius: 4px;
          margin-top: 15px;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default StripePayment;
