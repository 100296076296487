import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import './index.css'
import './App.css'
import './tailwind.scss'
import Footer from './components/Footer'
import Navigation from './components/Navigation'
import Contact from './pages/Contact'
import Book from './pages/Book'
import ConsultationIntro from './pages/ConsultationIntro'
import ThankYou from './pages/ThankYou'
import Payment from './pages/Payment'
import LogIn from './pages/LogIn'
import Admin from './pages/Admin'
import Workouts from './pages/Workouts'
import WorkoutDetail from './pages/WorkoutDetail'
import ProtectedRoute from './components/ProtectedRoute'

function App() {
  return (
    <>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/book-consultation" element={<ConsultationIntro />} />
      <Route path="/booking-form" element={<Book />} />
      <Route path="/workouts" element={<Workouts />} />
      <Route path="/workouts/:id" element={<WorkoutDetail />} />
      <Route path="/login" element={<LogIn />} />
      <Route path="/admin" element={
        <ProtectedRoute>
          <Admin />
        </ProtectedRoute>
      } />
      <Route path="/contact" element={<Contact />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/thank-you" element={<ThankYou />} />
    </Routes>
    <Footer />
    </>
  )
}

export default App
