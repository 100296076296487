import React from 'react';

const ServiceBox = ({ title, children, image }) => {
  return (
    <div className="flex-1 p-6 bg-white rounded-lg shadow-lg m-4 min-w-[300px] transform hover:scale-105 transition-transform duration-300">
      <img src={image} alt={title} className="w-full h-48 object-cover rounded-lg mb-6" />
      <h3 className="text-2xl font-bold mb-4 text-primary">{title}</h3>
      <div className="text-gray-700 fontPrimary">
        {children}
      </div>
    </div>
  );
};

export default ServiceBox;
